<template>
  <div>
    <SearchLine
      theme="white"
      ref="searchLine"
    />
    <template v-if="error">
      {{ error }}
    </template>
    <h2>Результаты поиска</h2>
    <template v-if="searchResult">
      <template v-if="searchResult.articles.data.length > 0">
        <h3>Результат поиска по материалам:</h3>
      </template>
      <div v-for="(search, index) in searchResult.articles.data" :key="index">
        <ArticleWildCard
            :article="search"
        />
      </div>
      <Pagination
          :total="searchResult.articles.pagination.total"
          table="articles"
          @changePage="changePage"
      />
      <template v-if="searchResult.youtube_videos.data.length > 0">
        <h3>Результат поиска по видео:</h3>
        <div v-for="(search, index) in searchResult.youtube_videos.data" :key="index">
          <YouTubeVideoWildCard
              :youTubeVideo="search"
          />
        </div>
        <Pagination
          :total="searchResult.youtube_videos.pagination.total"
          table="youtube_videos"
          @changePage="changePage"
        />
      </template>
    </template>
    <template v-else>
      По запросу "{{ searchQuery }}" ничего не найдено
    </template>
  </div>
</template>

<script>
import SearchLine from "@/components/search/SearchLine";
import axiosInstance from "@/axiosInstance";
import YouTubeVideoWildCard from "@/components/youTube/YouTubeVideoWildCard";
import ArticleWildCard from "@/components/news/ArticleWildCard";
import Pagination from "@/components/Pagination";

export default {
  name: 'SearchDetail',
  components: { ArticleWildCard, YouTubeVideoWildCard, SearchLine, Pagination },
  data() {
    return {
      error: null,
      searchResult: null,
      searchQuery: null,
    }
  },
  watch: {
    '$route.query.q'(searchQuery) {
      this.searchQuery = searchQuery;
      this.initSearch(searchQuery);
    }
  },
  mounted() {
    this.searchQuery = this.$route.query.q;
    this.initSearch(this.$route.query.q);
  },
  methods: {
    initSearch(searchQuery) {
      this.getSearchData(searchQuery);
      this.$refs.searchLine.setSearchText(searchQuery);
    },
    async getSearchData(searchQuery) {
      if (searchQuery) {
        await axiosInstance
            .post(
                `search/${searchQuery}`,
            ).then((response) => {
              this.searchResult = response.data;
            });
      } else {
        this.error = 'Строка поиска пуста'
      }
    },
    async changePage(page, table) {
      if (this.searchQuery) {
        await axiosInstance
            .post(
                `search/${this.searchQuery}`,
                {
                  page,
                  table,
                }
            ).then((response) => {
              this.searchResult[table].data = response.data.data;
            });
      } else {
        this.error = 'Строка поиска пуста'
      }
    },
  }
}
</script>
