<template>
  <div class="search-article-box">
    <div class="search-article-header">
      <div>
        <h3>{{ article.title }}</h3>
      </div>
      <div class="date">{{ article.created_at }}</div>
    </div>
    <div class="search-article-body">
      <div class="left-block">
        <span v-html="article.text_preview"></span>
        <router-link :to="article.code">
          <button>
            Читать полностью <i class="fas fa-arrow-circle-right"></i>
          </button>
        </router-link>
      </div>
      <div class="right-block">
        <img :src="article.image">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArticleWildCard',
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.search-article-box {
  margin-bottom: 20px;

  .search-article-header {
    display: flex;
    margin-bottom: 15px;

    div {
      width: 50%;

      h3 {
        margin: 0;
        text-transform: uppercase;
        text-decoration: none;
      }

      &.date {
        text-align: right;
      }
    }
  }

  .search-article-body {
    display: flex;

    .left-block {
      width: calc(100% - 15rem);

      span {
        padding-right: 15px;
      }

      a:hover {
        text-decoration: none;
      }

      button {
        margin: 15px 0 15px 0;
        display: block;
      }
    }

    .right-block {
      width: 15rem;

      img {
        width: 100%;
      }
    }
  }
}
</style>
