<template>
  <div class="pagination-block">
    <div v-for="(page, index) in paginationObj" :key="index" class="pagination-button" @click="changePage(page)" :class="getClass(page)">
      {{ page }}
    </div>
  </div>
</template>

<script>
// TODO Записывать в get параметр текущую страницу с учётом сущности пагинации и преодер
export default {
  name: 'Pagination',
  props: {
    total: {
      type: Number,
      required: true,
    },
    table: {
      type: String,
      required: true,
    },
    perPage: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    lastPage() {
      return this.total / this.perPage;
    },
    paginationObj() {
      let pagination = {};

      for (var i = 1; i < this.lastPage; i++) {
        pagination[i] = i;
      }

      return pagination;
    },
  },
  methods: {
    getClass(page) {
      if (page === this.currentPage) {
        return 'current';
      }
    },
    changePage(page) {
      if (this.currentPage !== page) {
        this.currentPage = page;
        this.$emit('changePage', page, this.table);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination-block {
  text-align: center;
  margin-bottom: 2rem;

  .pagination-button {
    display: inline-block;
    border: 2px solid var(--main-border-color);
    margin: 0 2px 0 2px;
    text-align: center;
    width: 30px;
    height: 30px;
    font-size: 20px;
    line-height: 30px;

    &:not(.current) {
      cursor: pointer;
    }

    &.current {
      color: var(--second-text-color);
      background: var(--main-block-color);
      cursor: not-allowed;
    }
  }
}
</style>
